import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/codebuild/output/src624843067/src/lenny-s.com/src/components/DefaultTemplate.js";
import Bio from '../components/Bio';
import SEO from '../components/seo';
import Games from '../components/Games';
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={props} components={components}>


      <SEO title="Games" keywords={[]} />
      <MDXTag name="h1" components={components}>{`Games and Prototypes`}</MDXTag>
      <MDXTag name="p" components={components}>{`In 2018 I started participating in short Game Jams to experiment with the
medium that inspired me to take up programming in high school. I submitted
5 games and found the experience and community really fulfilling and fun to be
a part of.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The constraints and low impact of each submitted game is a refreshing change
from the work I do during the day.`}</MDXTag>
      <Games />
           </MDXTag>;
  }

}
export const _frontmatter = {};
    